import Right from '@/assets/right.png';
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import store from '@/store';
import TopTitle from '@/components/TopTitle.vue';
export default {
  name: 'CollectHome',
  components: {
    TopTitle
  },

  setup() {
    const router = useRouter();
    const route = useRoute();

    if (route.query.channel !== undefined && route.query.channel !== null && route.query.channel === 'huawei') {
      route;
    }

    const info = computed(() => {
      return store.getters.invInfo;
    });

    function setupWKWebViewJavascriptBridge(callback) {
      if (window.WKWebViewJavascriptBridge) {
        return callback(window.WKWebViewJavascriptBridge);
      }

      if (window.WKWVJBCallbacks) {
        return window.WKWVJBCallbacks.push(callback);
      }

      window.WKWVJBCallbacks = [callback];
      window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
    }

    const back = () => {
      setupWKWebViewJavascriptBridge(function (bridge) {
        const param = {
          method: 'closeWindow',
          params: {
            url: info.value.url
          },
          callback: ''
        };
        bridge.callHandler('testiOSCallback', param, function () {});
      }); // } else {
      //   router.back()
      // }
    };

    return {
      Right,
      back,
      router,
      info
    };
  }

};