import icon from '@/assets/invite/icon.png';
import wechatjt from '@/assets/wechatjt.png';
import android from '@/assets/android.png';
import downMid from '@/assets/down-mid.png';
import safe from '@/assets/safe.png';
import mores from '@/assets/mores.png';
import down_back from '@/assets/invite/down_back.png';
import safari from '@/assets/safari.png';
import liulan from '@/assets/invite/liulan.png';
import share_point from '@/assets/invite/share_point.png';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
export default {
  name: 'DownPage',

  setup() {
    document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;';
    const route = useRoute();
    const isWechat = ref(false);
    const show = ref(false);
    const isIos = ref(false);
    const userAgent = navigator.userAgent.toLowerCase();
    const match = userAgent.match(/MicroMessenger/i);

    if (match !== null && match.toString() === 'micromessenger') {
      isWechat.value = true;
    }

    isIos.value = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

    const close = () => {
      show.value = false;
    }; //下载按钮点击时调用方法


    const down = () => {
      if (isWechat.value) {
        show.value = true;
      } else {
        if (route.params.code !== undefined && route.params.code !== '') {
          window.location.href = 'https://api.xiaoquanqianshou.cn/web-api/index/download/' + route.params.code;
        } else {
          window.location.href = 'https://www.xiaoquanqianshou.cn/download/androidRelease.apk';
        }
      }
    };

    const toIos = () => {
      if (isWechat.value) {
        show.value = true;
      } else {
        down();
      }
    };

    onMounted(() => {// const u = navigator.userAgent
      // const isAndroid = userAgent.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
      // const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      // if (!isWechat.value && !isAndroid && !isIOS) {
      //   window.location.href = 'https://www'
      // }
    });
    return {
      icon,
      down,
      wechatjt,
      isWechat,
      show,
      android,
      safe,
      mores,
      down_back,
      safari,
      isIos,
      close,
      liulan,
      share_point,
      downMid,
      toIos
    };
  }

};