import { punishList } from '@/api';
import punish from '@/assets/punish.png';
import punishBanner from '@/assets/punish-banner.png';
import { onMounted, ref } from 'vue';
export default {
  name: 'PunishPage',
  components: {},

  setup() {
    document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;';
    const list = ref([]);
    onMounted(() => {
      punishList().then(res => {
        if (res.code === 0) {
          list.value = res.data;
        }
      });
    });

    const toDay = time => {
      const date = new Date(time);
      return date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2);
    };

    const toType = (type, count) => {
      if (type === 1) {
        return '禁言' + count + '天';
      } else {
        return '永久封号';
      }
    };

    return {
      list,
      toDay,
      toType,
      punish,
      punishBanner
    };
  }

};