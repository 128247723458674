// @ is an alias to /src
// import store from '@/store'
// import { computed } from 'vue'
import { onMounted } from 'vue';
import background from '@/assets/background.jpg';
import ios from '@/assets/ios-black.png';
import beian from '@/assets/beian.png';
import quan from '@/assets/icon.png';
import homeone from '@/assets/homeone.png';
import hometwo from '@/assets/hometwo.png';
import homethree from '@/assets/homethree.png';
import homefour from '@/assets/homefour.png';
import homefive from '@/assets/homefive.png';
import itemback from '@/assets/itemback.png';
import shadow from '@/assets/shadow.png';
import ContactTip from '@/components/ContactTip';
import { ref } from 'vue';
import { saveAs } from 'file-saver';
export default {
  name: 'HomeView',
  components: {
    ContactTip
  },

  setup() {
    // const user = computed(() => {
    //   return store.getters.getUserInfo
    // })
    onMounted(() => {// const ua = navigator.userAgent
      // const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
      //     isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
      //     isAndroid = ua.match(/(Android)\s+([\d.]+)/)
      // if (isIphone || isAndroid) {
      //   window.location.href = 'https://www.xiaoquanqianshou.cn/down-page'
      // }
    });

    const bigImg = () => {
      big.value = !big.value;
    };

    const downloadAndroid = () => {
      saveAs('https://www.xiaoquanqianshou.cn/download/androidRelease.apk', 'xiaoquanqianshou.apk');
    };

    const downIos = () => {
      window.location.href = 'itms-services://?action=download-manifest&url=https://www.piaoliupingjiaoyou.cn/download/manifest.plist';
    };

    const tips = ref();
    const big = ref(false);
    return {
      // user,
      ios,
      tips,
      bigImg,
      big,
      downloadAndroid,
      quan,
      downIos,
      beian,
      background,
      homeone,
      hometwo,
      homethree,
      homefour,
      homefive,
      itemback,
      shadow
    };
  }

};