import store from '@/store';
import { nextTick } from 'vue'; // @ts-ignore
// import VConsole from 'vconsole/dist/vconsole.min'
// const vConsole = new VConsole()

export default {
  name: 'CollectBase',

  setup() {
    document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;';

    function setupWKWebViewJavascriptBridge(callback) {
      if (window.WKWebViewJavascriptBridge) {
        return callback(window.WKWebViewJavascriptBridge);
      }

      if (window.WKWVJBCallbacks) {
        return window.WKWVJBCallbacks.push(callback);
      }

      window.WKWVJBCallbacks = [callback];
      window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
    }

    nextTick(() => {
      setupWKWebViewJavascriptBridge(function (bridge) {
        const param = {
          method: 'getUserInfo',
          params: {},
          callback: 'onReceiveUser'
        }; // window.HybridAPI.sendToNative(JSON.stringify(param))

        bridge.registerHandler('onReceiveUser', function (msg) {
          const data = JSON.parse(msg);
          store.dispatch('SaveToken', data.token);
        });
        bridge.callHandler('testiOSCallback', param, function () {}); // if (window.HybridAPI !== undefined) {
        //   window.HybridAPI.onReceiveUser = (msg) => {
        //     const data = JSON.parse(msg)
        //     store.dispatch('SaveToken', data.token)
        //   }
        //   const param = {
        //     method: 'getUserInfo',
        //     params: {},
        //     callback: 'onReceiveUser'
        //   }
        //   // window.HybridAPI.sendToNative(JSON.stringify(param))
        //   bridge.callHandler('testiOSCallback', param, function(response) {
        //   })
        // }
      });
    });
    return {// vConsole
    };
  }

};